@import url("https://fonts.googleapis.com/css2?family=Jaldi:wght@400;700&family=Josefin+Sans:ital,wght@0,100..700;1,100..700&display=swap");

:root {
  --textcolor: #eeebe6;
  --bgcolor: #16181b;
  --normal: #a8a77a;
  --fire: #ee8130;
  --water: #6390f0;
  --electric: #f7d02c;
  --grass: #7ac74c;
  --ice: #96d9d6;
  --fighting: #c22e28;
  --poison: #a33ea1;
  --ground: #e2bf65;
  --flying: #a98ff3;
  --psychic: #f95587;
  --bug: #a6b91a;
  --rock: #b6a136;
  --ghost: #735797;
  --dragon: #6f35fc;
  --dark: #705746;
  --steel: #b7b7ce;
  --fairy: #d685ad;
  --pkmnyellow: #ffcb05;
  --pkmnblue: #2a75bb;
  --pkmndarkyellow: #c7a008;
  --pkmndarkblue: #3c5aa6;
}

/* html,
body {
  height: 100%;
} */

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.container {
  flex: 1;
}

.Toastify__toast-body {
  font-family: "Jaldi", sans-serif !important;
  font-size: 1rem !important;
}

.Toastify__progress-bar--bg {
  background: var(--ghost) !important;
}

.Toastify__progress-bar {
  background: var(--ghost) !important;
}

body {
  background-color: var(--bgcolor);
  color: var(--textcolor);
  font-family: "Jaldi", sans-serif;
  font-weight: 400;
  display: flex;
  flex-direction: column;
}

header,
div.mobile-header-search {
  background-color: rgba(5, 5, 5, 0.4);
  padding: 10px;
  display: flex;
  align-items: center;
  box-shadow: 0px 11px 10px 1px rgba(0, 0, 0, 0.3);
}

footer {
  background-color: rgba(5, 5, 5, 0.4);
  padding: 50px;
  margin-top: 50px;
  min-height: 100px;
  text-align: center;
}

header > div > a:link > span,
header > div > a:visited > span,
header > div > div > div.dropdown-toggle {
  font-family: "Josefin Sans", sans-serif;
  color: var(--textcolor);
  margin: 0px;
  padding: 10px;
  transition: 0.15s ease-in-out;
}

header > div > a:hover > span,
header > div > a:active > span,
header > div > div > div.dropdown-toggle:hover {
  font-family: "Josefin Sans", sans-serif;
  color: #ffffff;
  margin: 0px;
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 5px;
}

header > div > div > div.dropdown-menu.show {
  text-align: left;
  border-radius: 5px;
  position: absolute !important;
  width: 200px;
  min-height: 100px;
  left: 50% !important;
  top: 120% !important;
  transform: translateX(-50%) !important;
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.4);
  background-color: var(--bgcolor) !important;
}

header > div > div > div > a.dropdown-item:link,
header > div > div > div > a.dropdown-item:visited {
  color: #fff !important;
  padding: 10px;
}

header > div > div > div > a.dropdown-item:hover,
header > div > div > div > a.dropdown-item:active {
  background-color: #2b2f35 !important;
  border-radius: 5px;
  color: #fff !important;
}

header > div > div > div > a.dropdown-no-hover:hover {
  background-color: transparent !important;
  cursor: default;
}

.modal-btn {
  border-radius: 5px !important;
  background-color: transparent !important;
  border: 0 !important;
}

.modal-btn:hover,
.modal-btn:active,
.modal-btn:focus {
  background-color: rgba(0, 0, 0, 0.4) !important;
  border: 0 !important;
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px;
}

.login-header-button {
  color: var(--textcolor);
}

.card-title,
.card-text {
  color: var(--textcolor);
  font-size: 1rem;
  padding: 5px 12px 12px 12px;
  margin-bottom: 0px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--textcolor);
}

input[type="checkbox"]:checked {
  background: var(--ghost) !important;
  border: none !important;
}

input[type="checkbox"]:checked::before {
  content: "\2713";
  color: #fff;
  position: relative;
  top: -2px;
  left: 3px;
}

/* range slider circle color */
input[type="range"]::-webkit-slider-thumb {
  border-radius: 2px;
  background: var(--ghost) !important;
}

input[type="range"]::-moz-range-thumb {
  background: var(--ghost) !important;
}

input[type="range"]::-ms-thumb {
  background: var(--ghost) !important;
}

input.form-range {
  max-width: 18vw;
}

.button {
  background-color: var(--ghost) !important;
  border-radius: 5px !important;
  border: none !important;
  margin-left: 30px;
  width: 100px;
  height: 50px;
}

button:hover,
button:active,
button:focus {
  border: 0px !important;
}

.search-button,
.search-button:visited {
  background-color: var(--ghost) !important;
  border-radius: 7px;
  border-left: none !important;
  border-right: 1px solid var(--ghost) !important;
  border-top: 1px solid var(--ghost) !important;
  border-bottom: 1px solid var(--ghost) !important;
  margin-left: -10px !important;
}

.search-button:hover,
.search-button:active,
.search-button:focus {
  background-color: #674e87 !important;
  border-radius: 7px;
  border-left: none !important;
  border-right: 1px solid var(--ghost) !important;
  border-top: 1px solid var(--ghost) !important;
  border-bottom: 1px solid var(--ghost) !important;
  margin-left: -10px !important;
}

.title-link,
.title-link:visited,
.title-link:hover,
.title-link:active {
  color: var(--bs-body-color) !important;
}

.bi.bi-search {
  color: #dee2e6;
  transition: 0.15s ease-in-out;
}

.bi.bi-search:hover {
  color: #595c5f;
}

a:link,
a:visited {
  color: var(--ghost);
  text-decoration: none;
}

a:hover,
a:active {
  color: var(--flying);
  transition: 0.15s ease;
}

.button:hover {
  background-color: var(--flying) !important;
  border: none !important;
}

.button:active,
.button:focus {
  background-color: var(--ghost) !important;
  border: none !important;
}

.my-3.card {
  border: none !important;
  background-color: transparent !important;
}

.card-image-col {
  max-width: 216px;
  margin: 11px 0px 11px 0px;
  justify-content: center;
}

.card-image {
  width: 200px;
  margin: 11px 8px 11px 8px;
  opacity: 0;
  transition: transform 0.5s ease, opacity 0.5s ease-in-out !important;
}

.card-image:hover {
  cursor: pointer;
  transform: scale(1.15);
}

.card-image-loaded {
  opacity: 1;
}

.card-image-collection {
  width: 200px;
  padding: 0px 16px 0px 16px;
  transition: transform 0.5s ease, opacity 0.5s ease-in-out !important;
}

.card-image-collection:hover {
  cursor: pointer;
  transform: scale(1.08);
}

.btn.filter,
.btn.filter:visited {
  background-color: var(--ghost) !important;
  border-radius: 0px;
  border: none !important;
  color: #fff;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn.filter:hover,
.btn.filter:active {
  background-color: var(--flying) !important;
  border-radius: 0px;
  border: none !important;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

div > form > div.card {
  border-radius: 0px;
  height: calc(100vh - 200px);
}

.card-desc-small-text {
  font-size: 1.1rem;
}

.results-individual {
  margin-top: 10vh;
  margin-left: 0;
}

.clear-button {
  margin-top: 0;
  width: 130px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none !important;
  box-shadow: none !important;
  border: 1px solid #dee2e6 !important;
  border-right: none !important;
}

.no-caret::after {
  display: none !important;
}

div.dropdown-menu.show {
  border-radius: 0px;
}

div > form.my-3 {
  max-width: 65vw;
  padding: 0 25px 0 25px;
}

.individual-image-col {
  min-height: auto;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.individual-page-image {
  width: 100%;
  position: relative;
}

.image-container {
  position: relative;
  overflow: hidden;
  width: 421px;
  height: 588px;
}

.image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 421px;
  height: 100%;
  opacity: 0;
  transition: background-color 0.3s ease-in-out, opacity 0.3s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
}

.image-container:hover .image-overlay {
  opacity: 1;
  background-color: rgba(22, 24, 27, 0.9);
  cursor: pointer;
}

.collection-card-container {
  width: 200px;
  min-height: 380px;
  background-color: rgba(0, 0, 0, 0.5);
  align-items: center;
  justify-content: center;
  display: inline-flex;
  flex-direction: column;
  margin: 5px;
  padding: 10px;
  border-radius: 8px;
  transition: 0.15s ease-in-out !important;
}

.collection-card-container:hover {
  background-color: rgba(115, 87, 151, 0.2);
}

.wishlist-card-container {
  width: 95%;
  background-color: rgba(0, 0, 0, 0.5);
  align-items: center;
  justify-content: space-between;
  display: inline-flex;
  flex-direction: row;
  margin: 5px;
  padding: 10px;
  border-radius: 8px;
  transition: 0.15s ease-in-out !important;
}

.collection-card-image {
  width: 170px;
  margin: 0px 0px 15px 0px;
  transition: transform 0.5s ease, opacity 0.5s ease-in-out !important;
}

.collection-card-image:hover {
  cursor: pointer;
  transform: scale(1.08);
}

.wishlist-card-image {
  width: 150px;
  transition: transform 0.5s ease, opacity 0.5s ease-in-out !important;
}

.wishlist-card-image:hover {
  cursor: pointer;
}

.card-collection-close-button {
  position: relative;
  left: 76px;
  top: 0px;
  transition: 0.15s ease-in-out !important;
}

.card-collection-close-button:hover {
  color: #ffffff !important;
  cursor: pointer;
}

.card-wishlist-close-button {
  position: relative;
  top: 0px;
  transition: 0.15s ease-in-out !important;
}

.card-wishlist-close-button:hover {
  color: #ffffff !important;
  cursor: pointer;
}

.list {
  margin-left: 15px;
}

.list-2 {
  margin-left: 30px;
}

.second-row {
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

div.list > p {
  margin-bottom: 0;
}

.list > p:last-child {
  margin-bottom: 8px;
}

.loading-div-root {
  position: fixed;
  top: 45vh;
  left: -100px;
}

.loading-div {
  position: fixed;
  top: 190px;
  left: 0;
  z-index: 1;
  background-color: var(--bgcolor);
  min-width: 100vw;
  min-height: calc(100% - 190px);
  color: var(--textcolor);
  align-items: calc((flex-start + flex-end) / 2);
}

div.spinner-border {
  margin-top: -50vh;
  margin-bottom: 30px;
}

.toast-body {
  background-color: var(--ghost);
  color: var(--textcolor);
  border-bottom-left-radius: calc(
    var(--bs-toast-border-radius) - var(--bs-toast-border-width)
  );
  border-bottom-right-radius: calc(
    var(--bs-toast-border-radius) - var(--bs-toast-border-width)
  );
}

.num-results-button {
  width: 130px;
  height: 40px;
  margin-right: 0px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 70px;
}

::-webkit-scrollbar {
  width: 8px;
}
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

div.card-body {
  padding-left: 0;
}

#temp-fix-for-filter-menu {
  min-height: auto;
}

.individual-price-container {
  border: 1px solid var(--bs-gray);
  border-radius: 10px;
  padding: 25px;
}

.launch-tcgplayer {
  color: var(--bs-gray) !important;
  transition: 0.15s ease-in-out;
}

.launch-tcgplayer:hover {
  color: #fff !important;
  cursor: pointer;
}

.main-search-bar {
  border-right: none !important;
}

input[type="text"] {
  width: 180px !important;
  -webkit-transition: width 0.35s ease-in-out;
  transition: width 0.35s ease-in-out;
}

input[type="text"]:focus {
  width: 260px !important;
}

input[type="text"].header-search-bar:focus {
  width: auto !important;
}

.form-control:focus {
  box-shadow: 0px 0px 5px var(--ghost) !important;
  outline: none;
}

select.subtype-select {
  font-size: 1rem;
  color: var(--bs-body-color);
  background-color: rgba(255, 255, 255, 0.95);
  border-top: 1px solid #dee2e6 !important;
  border-bottom: 1px solid #dee2e6 !important;
  border-left: 1px solid #dee2e6 !important;
  border-right: none !important;
  width: 100px;
  margin-right: 10px;
  padding-left: 5px;
}

.MuiPagination-outlined {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  margin-top: 50px;
  margin-bottom: 50px;
}

.css-r93niq-MuiButtonBase-root-MuiPaginationItem-root {
  font-family: "Jaldi", sans-serif !important;
  color: #fff !important;
  border: 1px solid rgba(0, 0, 0, 0.4) !important;
  transition: 0.15s ease-in-out !important;
}

.css-r93niq-MuiButtonBase-root-MuiPaginationItem-root:active {
  border: none !important;
}

.css-r93niq-MuiButtonBase-root-MuiPaginationItem-root:hover,
.css-r93niq-MuiButtonBase-root-MuiPaginationItem-root:active {
  background-color: rgba(0, 0, 0, 0.4) !important;
  color: #fff !important;
  border: 1px solid rgba(0, 0, 0, 0.4) !important;
  transform: scale(1.2) !important;
}

.css-r93niq-MuiButtonBase-root-MuiPaginationItem-root:focus {
  border: 1px solid rgba(0, 0, 0, 0.4) !important;
}

.css-r93niq-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
  background-color: var(--ghost) !important;
  border: 1px solid var(--flying) !important;
  color: #fff !important;
  transform: scale(1.2) !important;
}

/* Fixes issue on deployed build */
.css-21ve8j.Mui-selected {
  font-family: "Jaldi", sans-serif !important;
  background-color: var(--ghost) !important;
  border: 1px solid var(--flying) !important;
  color: #fff !important;
  transform: scale(1.2) !important;
}

.css-21ve8j.Mui-selected:hover {
  font-family: "Jaldi", sans-serif !important;
  background-color: var(--ghost) !important;
  border: 1px solid var(--flying) !important;
  color: #fff !important;
  transform: scale(1.2) !important;
}

.css-21ve8j {
  font-family: "Jaldi", sans-serif !important;
  color: #fff !important;
  border: 1px solid rgba(0, 0, 0, 0.4) !important;
  transition: 0.15s ease-in-out !important;
}

.css-21ve8j:hover,
.css-21ve8j:active {
  background-color: rgba(0, 0, 0, 0.4) !important;
  color: #fff !important;
  border: 1px solid rgba(0, 0, 0, 0.4) !important;
  transform: scale(1.2) !important;
}

.css-21ve8j:focus {
  border: 1px solid rgba(0, 0, 0, 0.4) !important;
}

.css-21ve8j.Mui-disabled {
  color: #fff !important;
}
/* End fixes issue on deployed build */

.series-card {
  padding: 5px;
}

.series-col {
  background-color: rgba(0, 0, 0, 0.15);
  width: 32%;
  transition: 0.3s ease-in-out;
}

.series-col:hover {
  background-color: rgba(0, 0, 0, 0.4);
  transform: scaleX(1.04) scaleY(1.053);
  cursor: pointer;
}

.bi.bi.bi-search:hover {
  color: var(--textcolor);
}

.series-row {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.series-row-loaded {
  opacity: 1;
}

.series-row-loaded:last-child {
  margin-bottom: 80px;
}

input::file-selector-button {
  color: var(--bs-body-color) !important;
  background-color: #f4f4f4 !important;
  border-right: 0px !important;
  border: 1px solid var(--textcolor);
}

.image-upload-input {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border-right: 0px;
}

.upload-button,
.upload-button:visited {
  background-color: var(--ghost) !important;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border: 1px solid var(--ghost) !important;
}

.upload-button:hover,
.upload-button:active,
.upload-button:focus {
  background-color: var(--flying) !important;
  border: 1px solid var(--flying) !important;
}

.logout-button,
.logout-button:visited {
  background-color: var(--ghost) !important;
  border: 1px solid var(--ghost) !important;
  border-radius: 5px;
  width: 80px;
}

.logout-button:hover,
.logout-button:active,
.logout-button:focus {
  background-color: var(--flying) !important;
  border: 1px solid var(--flying) !important;
}

.profile-details {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.dropdown-toggle::after {
  display: none;
}

.dropdown-toggle:hover {
  cursor: pointer;
}

.mobile-profile-dropdown-container {
  display: none;
}

.newest-set-img {
  width: 300px;
  margin-top: 25px;
  margin-bottom: 10px;
  opacity: 0;
  transition: transform 0.5s ease, opacity 0.5s ease-in-out !important;
}

.newest-set-cards {
  margin-right: 10px;
  width: 200px;
  opacity: 0;
  transition: transform 0.5s ease, opacity 0.5s ease-in-out !important;
  z-index: 1;
}

.newest-set-cards-loaded,
.newest-set-img-loaded {
  opacity: 1;
}

.newest-set-img:hover {
  cursor: pointer;
  transform: scale(1.05);
}

.newest-set-cards:hover {
  cursor: pointer;
  transform: scale(1.07);
}

.newest-set-cards:last-child {
  margin-right: 0px;
}

.span-link {
  color: var(--ghost);
  transition: 0.15s ease-in-out;
}

.span-link:hover {
  cursor: pointer;
  color: var(--flying);
}

.login-container {
  width: 300px;
  background-color: rgba(5, 5, 5, 0.4);
  border-radius: 15px;
}

.form-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 25px;
}

.password-credentials-red {
  background-color: rgba(255, 0, 0, 0.3) !important;
  width: 100%;
  height: 25px;
}

.password-credentials-green {
  background-color: rgba(0, 128, 0, 0.3) !important;
  width: 100%;
  height: 25px;
}

form.login-form {
  width: 80% !important;
}

.password-input {
  border-radius: 5px !important;
}

.show-password-icon {
  background-color: transparent;
  color: rgba(33, 37, 41, 0.2);
  position: absolute;
  top: 8px;
  right: 15px;
  transition: 0.15s ease-in-out !important;
  z-index: 1000 !important;
}

.show-password-icon:hover {
  cursor: pointer;
  color: rgba(33, 37, 41, 0.5);
}

.create-account-btn {
  width: 100%;
  height: 45px;
  margin-top: 25px;
  margin-bottom: 25px;
  border-radius: 5px !important;
  background-color: var(--ghost) !important;
  border: 0px !important;
}

.create-account-btn:hover {
  border-radius: 5px !important;
  background-color: var(--flying) !important;
  border: 0px !important;
}

#profile-image {
  max-height: 25px !important;
  max-width: 25px !important;
  min-height: 25px !important;
  min-width: 25px !important;
  object-fit: cover;
}

.socials-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.google-icon,
.facebook-icon,
.x-icon {
  color: var(--ghost);
  transition: 0.15s ease-in-out !important;
}

.google-icon:hover,
.facebook-icon:hover,
.x-icon:hover {
  cursor: pointer;
  color: var(--flying);
}

.popup-content {
  font-size: inherit;
  font-family: "Jaldi", sans-serif !important;
  color: var(--textcolor) !important;
  border: none !important;
  border-radius: 10px !important;
  background-color: var(--bgcolor) !important;
  width: 15% !important;
}

.modal-header {
  width: 100%;
  border-bottom: 1px solid gray;
  font-size: 18px;
  padding: 5px;
}

.modal-content {
  width: 100%;
  padding: 10px 5px;
}

button.add-to-collection {
  background-color: var(--ghost) !important;
  border-radius: 0px;
  border: none !important;
  width: 80%;
}

.series-row-splider {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 10px;
  width: 25vw;
}

.series-row-splider:hover {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.4);
}

.series-row-logo-spilder,
.series-row-image-splider {
  transition: transform 0.5s ease-in-out;
}

.series-row-logo-spilder:hover,
.series-row-image-splider:hover {
  cursor: pointer;
  transform: scale(1.1);
}

.series-row-image-splider {
  margin-top: 25px;
}

.modal-content {
  background-color: var(--bgcolor);
}

.add-to-button {
  background-color: var(--ghost);
  border: none;
}

.add-to-button:hover {
  background-color: var(--flying);
  border: none;
}

.add-to-button:active {
  background-color: var(--ghost) !important;
  border: none !important;
}

.add-to-button:focus {
  border: none !important;
}

#tcg-player-button,
#tcg-player-button:active {
  background-color: var(--ghost) !important;
  border: none !important;
  width: 137px;
  transition: 0.15s ease-in-out;
}

#tcg-player-button:hover {
  background-color: var(--flying) !important;
  border: none;
}

/* mobile styles */
@media only screen and (max-width: 576px) {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: inherit;
  }

  a > h1 {
    color: var(--textcolor);
  }

  header {
    padding: 5px 0px 5px 0px;
  }

  .button {
    margin-top: 30px;
  }

  .btn.mobile-filter,
  .btn.mobile-filter:visited {
    background-color: var(--ghost) !important;
    border-radius: 0px;
    border: none !important;
    color: #fff;
    width: 40px;
    height: 40px;
  }

  .btn.mobile-filter:hover,
  .btn.mobile-filter:active {
    background-color: var(--flying) !important;
    border-radius: 0px;
    border: none !important;
    color: #fff;
  }

  .btn.filter,
  .btn.filter:visited {
    background-color: var(--ghost) !important;
    border-radius: 0px;
    border: none !important;
    color: #fff;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .btn.filter:hover,
  .btn.filter:active {
    background-color: var(--flying) !important;
    border-radius: 0px;
    border: none !important;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .results-button {
    display: none;
  }

  .main-filter {
    display: none;
  }

  .no-caret::after {
    display: none !important;
  }

  .individual-image-col {
    min-height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .individual-page-image {
    width: 90%;
  }

  div.first-row.row {
    padding: 25px 0 25px 0;
  }

  .results-individual {
    display: none;
  }

  .dropdown-menu {
    min-width: 100vw;
    min-height: 100vh;
    background-color: var(--bgcolor);
    border: none !important;
    color: var(--text);
  }

  button.dropdown-toggle::after {
    content: none;
  }

  div > form.my-3 {
    min-width: 100vw;
    padding: 0 25px 0 25px;
  }

  .num-results-button {
    width: 120px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .clear-button {
    width: 120px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: -10px;
  }

  input.form-range {
    max-width: 100vw;
  }

  .loading-div {
    top: 180px;
    left: 0;
    z-index: 1;
    background-color: var(--bgcolor);
    min-width: 100vw;
    min-height: calc(100% - 180px);
    color: var(--textcolor);
  }

  #collapse-card-special {
    padding-left: 0px;
    padding-right: 10px;
  }

  #temp-fix-for-filter-menu {
    margin-bottom: 15vh;
  }

  .price-list {
    margin-left: 0px;
  }

  .individual-price-container {
    border: none;
    margin: 0px;
  }

  .launch-tcgplayer {
    color: var(--bs-gray);
  }

  .launch-tcgplayer:hover {
    color: #fff;
    cursor: pointer;
    transition: 0.15s ease-in-out;
  }

  .individual-card-info {
    padding-top: 15px;
  }

  .series-col {
    width: 48%;
  }

  div.text-center.loading-div-mobile {
    width: 70vw !important;
  }

  .card-image {
    max-width: 185px;
  }

  .card-image:hover {
    transform: scale(1.13);
  }

  .dropdown-menu.show {
    display: none;
  }

  .mobile-profile-dropdown-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 9999999999;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .mobile-profile-dropdown {
    text-align: center;
    min-width: 100%;
    min-height: 100%;
    background-color: #070809 !important;
    border-radius: 5px;
    box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.4);
    padding: 25px;
    align-content: start;
    text-align: start;
    font-size: 1.3rem;
  }

  div > div > div > div > a:link,
  div > div > div > div > a:visited {
    color: #fff !important;
    padding: 15px;
  }

  div > div > div > div > a:hover,
  div > div > div > div > a:active {
    background-color: #2b2f35 !important;
    border-radius: 5px;
  }

  .newest-set-cards {
    width: 180px;
  }

  .newest-set-cards:hover {
    transform: scale(1.08);
  }

  .individual-page-image {
    width: 100%;
    position: relative;
  }

  .image-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 406px;
    height: 100%;
    opacity: 0;
    transition: background-color 0.3s ease-in-out, opacity 0.3s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .card-title,
  .card-text {
    color: var(--textcolor);
    font-size: 1rem;
    margin-bottom: 0px;
  }

  .collection-card-container {
    width: calc(100vw / 2.23);
  }

  .image-container {
    width: 382px;
    height: 533px;
  }

  .login-container {
    background-color: #0f1012;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    padding: 0px;
    border-radius: 0px;
    z-index: 9999;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
  }

  .return-link {
    position: absolute;
    top: 0;
    left: 0;
    margin-left: 25px !important;
    margin-top: 25px !important;
    padding: 0px !important;
  }

  .popup-content {
    width: 70% !important;
  }

  .series-row-splider {
    min-width: 100vw;
  }

  .wishlist-card-image {
    width: 25px !important;
  }

  .wishlist-card-image {
    width: 33% !important;
    min-height: 100%;
    transition: transform 0.5s ease, opacity 0.5s ease-in-out !important;
  }

  .mobile-card-wishlist-name,
  .mobile-card-wishlist-price {
    display: none;
  }

  .mobile-card-wishlist-functions {
    width: 66% !important;
    margin-left: 25px !important;
    align-items: center !important;
  }
}

.getting-sets-loading-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90vh;
}

@media screen and (min-width: 576px) and (max-width: 768px) {
  .container {
    padding-left: calc(100vw * 0.04);
    min-width: 100%;
  }

  .header-container {
    display: flex;
    justify-content: space-between !important;
    padding: 0px;
  }

  .newest-set-cards {
    width: 127px;
    z-index: 1000;
  }

  .popup-content {
    width: 70% !important;
  }
}

@media only screen and (max-width: 390px) {
  .series-col {
    width: 47.9%;
  }
}

@media only screen and (max-width: 375px) {
  .collection-card-image {
    width: 100%;
  }

  .card-collection-close-button {
    position: relative;
    left: 65px;
    top: -25px;
  }
}

@media only screen and (min-width: 576px) {
  .btn.mobile-filter,
  .btn.mobile-filter:visited {
    display: none;
  }

  .btn.mobile-filter:hover,
  .btn.mobile-filter:active {
    display: none;
  }
}
